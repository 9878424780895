@font-face {
  font-family: Riffic Free;
  src: url(RifficFree-Bold.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(Poppins-Regular.ttf);
}
html {
  scroll-behavior: smooth;
}
.App {
  background: linear-gradient(180deg, #010922 0%, rgba(1, 9, 34, 0.84) 48.44%, #010922 100%);
}

#roadmap {
  background-image: url('./assests/Roadmap.png');
  background-position: center;
  background-size: cover;
}

#team {
  background-image: url('./assests/Featuers.png');
  background-position: center;
  background-size: cover;
}

.riffic {
  font-family: Riffic Free;
}

.poppins {
  font-family: Poppins;
}

.decorated-text {
  background: linear-gradient(180deg, #FF29E2 -51.72%, #FFB015 113.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-dots li button:before {
  color: white !important;
}

.mintButton {
  background: linear-gradient(90deg, #FD4B38 0%, #5B01FB 100%);
  box-shadow: 0px 5px 8.00838px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.Box {
  background: linear-gradient(180deg, #FF29E2 0%, #FFB015 100%);
  box-shadow: 15px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
}

.gradient-bg {
  background: linear-gradient(180deg, #FF29E2 -31.19%, #FFB015 142.38%);
}

.txt-sdw {
  text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.headerAnimate {
  transition: transform 0.5s ease-in;
}

#section3, #extraSection {
  min-height: 90vh;
  background: url('./assests/Section3.png');
}

#section5 {
  min-height: 90vh;
  background-repeat: no-repeat;
  background: url('./assests/bg-section3.png');
}

.section6 {
  background-image: url('./assests/Section6.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
}

video {
  border-radius: 40px;
  padding: 5px;
  background: linear-gradient( #FF29E2 0%, #FFB015 100%);  
}

@media(max-width: 640px) {
  video {
    border-radius: 10px;
    padding: 3px;
  }
}

body::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #111042;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ffebef;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
}

.section1 {
  background-image: url('./assests/Section1.png');
  background-position: center;
  background-size: cover;
}

.section2 {
  background: linear-gradient(180deg, #010922 0%, #2E3F72 52.08%, #010922 100%);
}

.section8 {
  background-image: url('./assests/Section8.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
}